import { Inject, Injectable, Optional } from '@angular/core';
import { MathApiException } from '@services/api.generated';
import { Response } from 'express';
import { EMPTY, ObservableInput, ObservedValueOf, OperatorFunction, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RESPONSE } from '../../../../express.tokens';
import { RoutePaths } from '../../../routes-constants';

export enum ErrorType {
  BadRequest = 'bad-request',
  Forbidden = 'forbidden',
  NotFound = 'not-found',
  ServerError = 'server-error',
}

export function handleResponseError<T, O extends ObservableInput<J>, J>(
  router: Router
): OperatorFunction<T, T | ObservedValueOf<O>> {
  return (source) =>
    source.pipe(
      catchError((e: unknown) => {
        if (e instanceof MathApiException) {
          switch (e.status) {
            case 400:
              router.navigate(['/', RoutePaths.ErrorRoute.root, RoutePaths.ErrorRoute.badRequest]);
              break;
            case 403:
              router.navigate(['/', RoutePaths.ErrorRoute.root, RoutePaths.ErrorRoute.forbidden]);
              break;
            case 404:
              router.navigate(['/', RoutePaths.ErrorRoute.root, RoutePaths.ErrorRoute.notFound]);
              break;
            case 500:
            default:
              router.navigate(['/', RoutePaths.ErrorRoute.root, RoutePaths.ErrorRoute.serverError]);
              break;
          }

          return EMPTY;
        }

        router.navigate(['/', RoutePaths.ErrorRoute.root, RoutePaths.ErrorRoute.serverError]);

        return throwError(e);
      })
    );
}

export function handleServerResponse<T>(router: Router): OperatorFunction<T, T> {
  return (source) => {
    return source.pipe(
      tap((data) => {
        if (!data) {
          router.navigate(['/', RoutePaths.ErrorRoute.root, RoutePaths.ErrorRoute.notFound]);
        }
      }),
      handleResponseError<T, ObservableInput<T>, T>(router)
    );
  };
}

@Injectable()
export class ErrorResponseService {
  constructor(@Optional() @Inject(RESPONSE) private res?: Response) {}

  setErrorCode(type: ErrorType): void {
    if (type === undefined) {
      this.res?.status(200);
      return;
    }

    let code;

    switch (type) {
      case ErrorType.BadRequest:
        code = 400;
        break;
      case ErrorType.Forbidden:
        code = 403;
        break;
      case ErrorType.ServerError:
        code = 500;
        break;

      case ErrorType.NotFound:
      default:
        code = 404;
    }

    this.res?.status(code);
  }
}
